import * as moment from 'moment';

export function isAuthorizedLol(refreshToken) {
    if (!refreshToken) {
        // get auth from session storage
        const auth = JSON.parse(sessionStorage.getItem('mc-auth'));
        // auth exists and auth not expired
        if (auth !== undefined && auth !== null) {
            const expires = moment(auth.expires);
            const now = moment().utc();
            const expired = expires.isBefore(now);
            if (!expired) {
                // auth object is valid, return authorized
                return true;
            }
        }

        refreshToken = JSON.parse(localStorage.getItem('mc-refresh-token'));
    }

    // if refresh token not exists, return not authorized
    if (refreshToken === undefined || refreshToken === null) {
        return false;
    }

    return fetch('/api/authorize/token', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({'token': refreshToken}),
    })
        .then(response => response.json())
        .then(newAuth => {
            if (!newAuth.valid) {
                return false;
            }
            sessionStorage.setItem('mc-auth', JSON.stringify(newAuth));
            if (newAuth.remainSignedIn) {
                localStorage.setItem('mc-refresh-token', JSON.stringify(newAuth.refreshToken));
            }
            return true;
        })
        .catch(() => false);
}
