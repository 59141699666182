import React, { useContext } from "react";
import { useParams} from "react-router-dom";
import * as moment from "moment";

import ParticipentList from "../components/ParticipentList";
import { H1 } from "../styles/globals";
import {store} from "../store/store";
import {fetchCourse} from "../utils/api";
import {
    BigText,
    Card,
    CardContent,
    CardList,
    CardTitle,
    Header,
    InnerContent, TrainerAvatar,
    TrainerName
} from "../styles/page-header";
import {getFormatedParticipantsTile} from "../utils/participants";

export default function Event() {
    const { id } = useParams();
    let { dispatch, state: { upcomingEvents, currentCourse, events, courses } } = useContext(store);

    let currentEvent = upcomingEvents.find(item => item.id === id);

    if(!currentEvent && !currentCourse && events.length > 0) {
        const event = events.find(item => item.id === id);
        if(event) {
            fetchCourse(event.course, dispatch);
        }
    }

    if(!currentEvent && currentCourse) {
        const events = currentCourse.courseSeries
            .reduce((acc, val) => acc.concat(val.events), []);

        currentEvent = events.find(item => item.id === id);
    }

    if(!currentEvent) {
        return '';
    }

    const courseByCourseList = courses.find(item => item.id === currentEvent.course);

    const groups = Object.values(currentEvent.participants
        .reduce((rv, x) => {
            const status = ['none', 'noshowup', 'participated'].includes(x.status) ? 'participants' : 'waitinglist';
            (rv[status] = rv[status] || []).push(x);
            return rv;
        }, {})
    );

    return (
        <>
            <Header>
                <H1>{ currentEvent.name }</H1>
                <p>{ moment(currentEvent.dateStart).format('D.M.YYYY') } <br/>
                { moment(currentEvent.dateStart).format('H:mm')} - { moment(currentEvent.dateEnd).format('H:mm') }</p>
            </Header>
            <CardList>
                <Card>
                    <CardTitle>Kursleiter</CardTitle>
                    <CardContent>
                        { currentEvent.trainer && currentEvent.trainer.profileImage && <TrainerAvatar alt="Trainer Avatar" src={currentEvent.trainer.profileImage}/> }
                        <TrainerName>{currentEvent.trainer && <span>{currentEvent.trainer.firstname} {currentEvent.trainer.lastname}</span> }</TrainerName>
                    </CardContent>
                </Card>
                <Card>
                    <CardTitle>Teilnehmer</CardTitle>
                    {currentEvent.participants && courseByCourseList && getFormatedParticipantsTile(currentEvent.participants, courseByCourseList.maxAttendees) }
                </Card>
                {
                    currentEvent && currentEvent.location &&
                    <Card>
                        <CardTitle>Ort</CardTitle>
                        <BigText>{currentEvent && currentEvent.location && currentEvent.location.name}</BigText>
                    </Card>
                }
            </CardList>
            <InnerContent>
                {
                    groups.map(group => <ParticipentList currentEvent={currentEvent} participants={group} title={['none', 'noshowup', 'participated'].includes(group[0].status) ? 'participants' : 'waitinglist'}/>)
                }
            </InnerContent>
        </>
    );
}
