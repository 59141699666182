import React, {useContext} from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import * as moment from "moment";
import {store} from "../store/store";
import {
    MenuTile,
    MenuTileContent,
    MenuTileHour,
    MenuTileTitle, MenuTileTrainer,
    Wrapper
} from "../styles/sidebar";

const EventColor = styled.div`
    height: 15px;
    width: 15px;
    border-radius: 50%;
    display: inline-block;
    margin-right: 10px;
    margin-top: 5px;
    
    ${props => props.color && `
        background: ${props.color}; 
    `}
`;

export const BackButton = styled.div`
    color: #333;
    font-weight: 500;
    margin-top: 10px;
`;

export const BackButtonText = styled.span`
    margin-left: 10px;
`;

export const BackButtonIcon = styled.svg`
    vertical-align: text-top;
`;

export default function CourseEventListSidebar({ back }) {
    const { id } = useParams();
    const { state: { currentCourse } } = useContext(store);

    const event = currentCourse && currentCourse.courseSeries
        .reduce((carry, item) => carry.concat(item.events), [])
        .map(event => {
            event.dateStart = moment(event.dateStart);
            return event;
        })
        .find(item => item.id === id);


    const courseSeries = event && currentCourse.courseSeries
        .find(item => item.id === event.courseSeries);

    return (
        <Wrapper>
            <div>
                <MenuTile key={'backlink'} to={`/courses/${event && event.course}`}>
                    <BackButton>
                        <BackButtonIcon width="20" height="17" viewBox="0 0 20 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M10 4.48113V1.25853C9.9996 1.00967 9.92609 0.766483 9.78873 0.559635C9.65137 0.352787 9.45631 0.191529 9.22813 0.0961827C8.99995 0.000836566 8.74886 -0.0243313 8.5065 0.0238514C8.26415 0.0720341 8.04137 0.191411 7.86625 0.366938L0 8.18478L7.86625 16.0014C7.98207 16.1187 8.11977 16.2118 8.27143 16.2754C8.4231 16.3389 8.58574 16.3716 8.75 16.3716C8.91426 16.3716 9.0769 16.3389 9.22857 16.2754C9.38023 16.2118 9.51793 16.1187 9.63375 16.0014C9.74989 15.8845 9.84201 15.7457 9.90486 15.5929C9.9677 15.4402 10 15.2764 10 15.111V11.9766C13.4375 12.0622 17.1938 12.6894 20 17V15.7407C20 9.90627 15.625 5.10827 10 4.48113Z" fill="black"/>
                        </BackButtonIcon>
                        <BackButtonText>Zurück zum Kurs</BackButtonText>
                    </BackButton>
                </MenuTile>
                {
                    courseSeries && courseSeries.events.map((item, key) =>
                        <MenuTile key={item.id} to={`/courses/${item.course}/events/${item.id}`} active={item.id === id ? 1 : 0}>
                            <EventColor color={item.color}></EventColor>
                            <MenuTileContent>
                                <MenuTileHour>{item.dateStart.format('H:mm')} Uhr</MenuTileHour>
                                <MenuTileTitle>{ item.dateStart.format('D.M.YYYY') }</MenuTileTitle>
                                <MenuTileTrainer>{item.trainer && <span>{item.trainer.firstname} {item.trainer.lastname}</span> }</MenuTileTrainer>
                            </MenuTileContent>
                        </MenuTile>
                    )
                }
            </div>
        </Wrapper>
    );
}
