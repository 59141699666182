import React, {useContext, useEffect, useState} from "react";
import styled from 'styled-components';
import DataTable from 'react-data-table-component';
import * as moment from "moment";
import { RRule } from 'rrule'

import GERMAN, {GERMAN_GETTEXT} from "../utils/rrule";
import { Button, ButtonLink } from "./Button";
import {store} from "../store/store";
import {getFormatedParticipantsNumber} from "../utils/participants";

const ButtonWrapper = styled.div`
    text-align: center;
`;
const CollapseIcon = styled.svg`
    margin-left: 10px;
    transform: rotate(180deg);
    position: absolute;
    right: 20px;
    top: 15px;
    
    ${props => props.collapsed && 
        'transform: rotate(0);'
    }
`;

const SeriesHeading = styled.h2`
    cursor: pointer;
    margin-top: 0;
    margin-bottom: 0;
    transition: background ease .5s;
    padding: 5px 15px;
    position: relative;
    font-weight: 300; 
    
    ${props => !props.collapsed && `
        margin-bottom: 10px;
    `}
    
    ${props => props.collapsed && `
        background: #F3F3F3;
    `}
`;

const Series = styled.div`
    border-radius: 3px;
    padding: 5px 15px;
    margin: 15px 0;
    
    ${props => !props.collapsed && `
        padding-bottom: 30px;
    `}
`;

function capitalizeFirstLetter(string) {
    return string.charAt(0).toLowerCase() + string.slice(1);
}

export default function CourseSeries({id, courseSeries}) {
    const { state: { courses, currentCourse } } = useContext(store);
    const courseByCourseList = courses.find(item => item.id === currentCourse.id);
    const [showAll, setShowAll] = useState(false);
    const [collapsed, setCollapsed] = useState(false);

    useEffect(() => {
        const yesterday = moment().subtract(1, 'days').utc();
        const hasUpcomming = courseSeries.events.find(event => {
            const courseTime = moment(event.dateStart);
            return courseTime.isAfter(yesterday)
        });
        if(!hasUpcomming) {
            setCollapsed(true);
        }
    }, [courseSeries.events]);

    if(courseSeries.events.length === 0) {
        return <></>;
    }

    const columns = [
        {
            name: 'Datum',
            sortable: false,
            cell: row => <span>{ moment(row.dateStart).format('D.M.YYYY') }</span>,
        },
        {
            name: 'Uhrzeit',
            sortable: false,
            cell: row => <span>{ moment(row.dateStart).format('H:mm') }</span>,
        },
        {
            name: 'Kursleiter',
            selector: 'trainer',
            sortable: false,
            right: false,
            cell: row => <span>{row.trainer && row.trainer.firstname} {row.trainer && row.trainer.lastname}</span>,
        },
        {
            name: 'Teilnehmer',
            selector: 'id',
            sortable: false,
            cell: row => <span>{ courseByCourseList && getFormatedParticipantsNumber(row.participants, courseByCourseList.maxAttendees)}</span>,
        },
        {
            name: '',
            selector: 'id',
            sortable: false,
            right: true,
            cell: row => <ButtonLink to={`/courses/${row.course}/events/${row.id}`}>Details</ButtonLink>,
        },
    ];

    const events = showAll ? courseSeries.events : courseSeries.events.slice(0, 10);

    const onClick = () => setShowAll(!showAll);

    const collapseClick = () => {
        return setCollapsed(!collapsed);
    };

    const startDate = moment(courseSeries.events[courseSeries.events.length - 1].dateStart).format('D.M.YYYY H:mm');

    return (
        <Series collapsed={collapsed}>
            {
                courseSeries.rRule &&
                <SeriesHeading onClick={collapseClick} collapsed={collapsed}>
                    {startDate}, { capitalizeFirstLetter(courseSeries.rRule && RRule.fromString(courseSeries.rRule).toText(GERMAN_GETTEXT, GERMAN)) }
                    <CollapseIcon collapsed={collapsed} width="27" height="14" viewBox="0 0 27 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1 2L13.5 12L26 2" stroke="black" stroke-width="3"/>
                    </CollapseIcon>
                </SeriesHeading>
            }
            { (!collapsed || !courseSeries.rRule)&&
                <div>
                    {
                        events.length > 0 &&
                        <div>
                            <DataTable
                                title="Termine"
                                columns={columns}
                                data={events}
                            />
                            {courseSeries.events.length > 10 &&
                                <ButtonWrapper><Button onClick={onClick}>{showAll ? 'Weniger anzeigen' : 'Mehr anzeigen'}</Button></ButtonWrapper>
                            }
                        </div>
                    }

                    {
                        events.length === 0 &&
                            <p>Zu dieser Kursserie existieren keine Termine.</p>
                    }
                </div>
            }
        </Series>
    );
}
