import React from "react";
import {Link} from "react-router-dom";

export function ButtonLink(props) {
    return (
        <Link {...props} className="e-control e-btn e-lib e-primary"> { props.children} </Link>
    );
}

export function Button(props) {
    return (
        <button {...props} className="e-control e-btn e-lib e-primary"> { props.children} </button>
    );
}
