import React, {useContext} from "react";
import { useParams } from "react-router-dom";
import * as moment from "moment";
import {store} from "../store/store";
import {
    EventColor,
    MenuCategory,
    MenuTile,
    MenuTileContent, MenuTileHour,
    MenuTileMeta,
    MenuTileTitle, MenuTileTrainer,
    Wrapper
} from "../styles/sidebar";
import {getFormatedParticipantsNumber} from "../utils/participants";

export default function EventListSidebar({ back }) {
    const { id } = useParams();
    let { state: { upcomingEvents, courses } } = useContext(store);
    const today = moment().utc();
    const tomorrow = moment().add(1, 'days').utc();

    upcomingEvents = upcomingEvents
        .map(event => {
            event.dateStart = moment(event.dateStart);
            event.group = event.dateStart.format('D.M.YYYY');
            event.today = event.group === today.format('D.M.YYYY');
            event.tomorrow = event.group === tomorrow.format('D.M.YYYY');
            return event;
        });

    const groups = Object.values(upcomingEvents
        // .filter(event => !event.today)
        .reduce(function(rv, x) {
            (rv[x.group] = rv[x.group] || []).push(x);
            return rv;
        }, {})
    );

    return (
        <Wrapper>
            {
                groups.map(group => <>
                    { group[0].today && <MenuCategory>Heute:</MenuCategory> }
                    { group[0].tomorrow && <MenuCategory>Morgen:</MenuCategory> }
                    { group[0].group && !group[0].today && !group[0].tomorrow && <MenuCategory>{group[0].group}:</MenuCategory> }
                    <div>
                        {
                            group.map((item, key) =>
                                <MenuTile big key={item.id} to={`/events/${item.id}`} active={item.id === id ? 1 : 0}>
                                    <EventColor color={item.color}></EventColor>
                                    <MenuTileContent>
                                        <MenuTileHour>{item.dateStart.format('H:mm')} Uhr</MenuTileHour>
                                        <MenuTileMeta>{getFormatedParticipantsNumber(item.participants, courses.find(course => course.id === item.course) && courses.find(course => course.id === item.course).maxAttendees)}</MenuTileMeta>
                                        <MenuTileTitle>{item.name}</MenuTileTitle>
                                        <MenuTileTrainer>{item.trainer && <span>{item.trainer.firstname} {item.trainer.lastname}</span> }</MenuTileTrainer>
                                    </MenuTileContent>
                                </MenuTile>
                            )
                        }
                    </div>
                </>)
            }
        </Wrapper>
    );
}
