import React, {useContext} from "react";
import { useLocation , useHistory, useParams} from "react-router-dom";
import Splitter from 'm-react-splitters';
import 'm-react-splitters/lib/splitters.css';

import styled from 'styled-components';

import { DropDownButtonComponent } from '@syncfusion/ej2-react-splitbuttons';

import {Content, SideBar} from "../styles/globals";
import EventListSidebar from "../components/EventListSidebar";
import CourseListSidebar from "../components/CourseListSidebar";
import {store} from "../store/store";
import Breadcrumbs from "../components/Breadcrumbs";
import CourseEventListSidebar from "../components/CourseEventListSidebar";

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

const Navigation = styled.div`
    // height: 70px;
    // display: flex;
    padding: 15px;
    border-bottom: 1px solid #d4d4d4;
`;

const SplitterWrapper = styled.div`
    // flex: 1;
    height: calc(100vh - 103px);
`;

const DropdownWrapper = styled.div`
    display: inline-block;
    vertical-align: super;
`;

const H1 = styled.h1`
    display: inline-block;
    margin: 0;
    margin-right: 30px;
    font-weight: 300;
    font-size: 32px;
`;

export default function SplitView({ children }) {
    let location = useLocation();
    const { push } = useHistory();
    const { id } = useParams();
    const { state: { courses, upcomingEvents, currentCourse } } = useContext(store);

    const breadcrumbs = [
        {
            url: `https://app.medocheck.com/`,
            label: 'medo.check',
            target: '_parent',
        },
        {
            url: `/`,
            label: 'Kurse',
        },
    ];

    let type = '';
    if(location.pathname.includes('events') && location.pathname.includes('courses')) {
        type = 'courseevent';
        breadcrumbs.push({
            url: `/courses/${currentCourse && currentCourse.id}`,
            label: 'Kurshistorie',
        });
        breadcrumbs.push({
            url: `/courses/${currentCourse && currentCourse.id}/events/${id}`,
            label: 'Termine',
        });
    } else if(location.pathname.includes('courses')) {
        type = 'course';
        breadcrumbs.push({
            url: `/courses/${courses[0] && courses[0].id}`,
            label: 'Kurshistorie',
        });
    } else {
        type = 'event';
        breadcrumbs.push({
            url: `/events/${upcomingEvents[0] && upcomingEvents[0].id}`,
            label: 'Aktuelle Kurstermine',
        });
    }

    const dropdownItems = [
        {
            text: 'Aktuelle Kurstermine'
        },
        {
            text: 'Kurshistorie'
        },
    ];

    const dropdownSelect = (args) => {
        if (args.item.text === 'Kurshistorie') {
            push(`/courses/${courses[0] && courses[0].id}`);
        }

        if (args.item.text === 'Aktuelle Kurstermine') {
            push(`/events/${upcomingEvents[0] && upcomingEvents[0].id}`);
        }
    };

    return (
        <Wrapper>
            <Navigation>
                <Breadcrumbs items={breadcrumbs}/>
                { type === 'courseevent' && <H1>Termine für { currentCourse && currentCourse.name}</H1>}
                { type === 'event' && <H1>Aktuelle Kurstermine</H1>}
                { type === 'course' && <H1>Kurshistorie</H1>}
                <DropdownWrapper>
                    <DropDownButtonComponent items={dropdownItems} select={dropdownSelect}> Ansicht wechseln </DropDownButtonComponent>
                </DropdownWrapper>
            </Navigation>
            <SplitterWrapper>
                <Splitter
                    position="vertical"
                    primaryPaneMaxWidth="30%"
                    primaryPaneMinWidth={'200px'}
                    primaryPaneWidth="300px"
                    postPoned={true}
                >
                    <SideBar>
                        { type === 'courseevent' && <CourseEventListSidebar/>}
                        { type === 'event' && <EventListSidebar/>}
                        { type === 'course' && <CourseListSidebar/>}
                    </SideBar>
                    <Content>
                        { children }
                    </Content>
                </Splitter>
            </SplitterWrapper>
        </Wrapper>
    );
}
