import React from "react";
import {
    Link,
} from "react-router-dom";

import styled from 'styled-components';

const Breadcrumb = styled.ul`
    margin: 0;
    padding: 0;
    list-style: none;
`;
const BreadcrumbItem = styled.li`
    margin: 0;
    padding: 0;
    list-style: none;
    display: inline-block;
    padding-right: 1rem;
    font-size: .8rem;
    font-weight: 400;
    color: #999;
    font-family: Roboto,Segoe UI,GeezaPro,"DejaVu Serif","sans-serif",-apple-system,BlinkMacSystemFont;
    
    :not(:first-child):before {
        display: inline-block;
        padding-right: .5rem;
        color: #6c757d;
        content: "/";
    }
`;

const BreadcrumbLink = styled(Link)`
    color: #999;
    text-decoration: none;
    font-size: .8rem;
    font-weight: 400;
`;

const A = styled.a`
    color: #999;
    text-decoration: none;
    font-size: .8rem;
    font-weight: 400;
`;

export default function Breadcrumbs({ items }) {
    return (
        <Breadcrumb>
            { items.map((item) => <BreadcrumbItem key={item.url+item.label} >
                { !item.target && <BreadcrumbLink to={item.url}>{ item.label }</BreadcrumbLink> }
                { item.target && <A target={item.target}  href={item.url}>{ item.label }</A> }
            </BreadcrumbItem>) }
        </Breadcrumb>
    );
}
