import React, { createContext, useReducer } from 'react';

const initialState = {
  employees: [],
  courses: [],
  locations: [],
  upcomingEvents: [],
  events: [],
  currentCourse: null,
  eventBusState: 'idle',
  errors: {
    employees: false,
    courses: false,
    locations: false,
    upcomingEvents: false,
    events: false,
    currentCourse: false,
  },
};
const store = createContext(initialState);
const { Provider } = store;

const StateProvider = ({ children }) => {
  const [state, dispatch] = useReducer((state, action) => {
    switch (action.type) {
      case 'setEmployees':
        return {
          ...state,
          employees: action.value,
        };
      case 'setCourses':
        return {
          ...state,
          courses: action.value,
        };
      case 'setLocations':
        return {
          ...state,
          locations: action.value,
        };
      case 'setUpcomingEvents':
        return {
          ...state,
          upcomingEvents: action.value,
        };
      case 'setUpcomingEventsParticipantionPending':
        return {
          ...state,
          upcomingEvents: state.upcomingEvents.map(event => {
            if(event.id === action.value.event.id) {
              const participantJSON = JSON.stringify(action.value.participant);
              event.participants = event.participants.map(participant => {
                //This ugly solution is needed due to 'participant' not having an id, furthermore enabling this code will trigger an API bug
                if(JSON.stringify(participant) === participantJSON) {
                  participant.loading = true;
                }

                //Because sending multiple PUT calls to the API at the same time will cause data loss, we only allow the user to do one request at a time
                // participant.loading = true;
                return participant;
              })
            }

            return event;
          }),
        };
      case 'setEvents':
        return {
          ...state,
          events: action.value,
        };
      case 'currentCourse':
        return {
          ...state,
          currentCourse: action.value,
          errors: {
            ...state.errors,
            currentCourse: false,
          }
        };
      case 'setEventBusState':
        return {
          ...state,
          eventBusState: action.value,
        };
      case 'setError':
        return {
          ...state,
          errors: {
            ...state.errors,
            ...action.value,
          },
        };
      default:
        throw new Error();
    }
  }, initialState);

  return <Provider value={{ state, dispatch }}>{children}</Provider>;
};

export { store, StateProvider };
