import { HubConnectionBuilder } from '@aspnet/signalr';
import {fetchCourses, fetchEmployees, fetchEvents, fetchLocations, fetchUpcomingEvents} from "./api";
import {toast} from "react-toastify";
import { debounce } from "debounce";

export function setUpSignalR(dispatch) {
    const connection = new HubConnectionBuilder()
    // .configureLogging(signalR.LogLevel.Error)
        .withUrl('/signalr/events')
        .build();

    connection
        .start()
        .then(() => {
            const { token } = JSON.parse(sessionStorage.getItem('mc-auth'));

            connection
                .invoke('Register', token)
                .then(() => {
                    // console.log('Connection has been registered');
                });

            connection.on('eventsChanged', debounce(() => {
                fetchUpcomingEvents(dispatch);
                fetchEvents(dispatch);
            }, 1000));

            connection.on('seriesChanged', debounce(() => {
                fetchUpcomingEvents(dispatch);
                fetchEvents(dispatch);
            }, 1000));

            connection.on('locationsChanged', debounce(() => {
                fetchLocations(dispatch);
            }, 1000));

            connection.on('coursesChanged', debounce(() => {
                fetchCourses(dispatch);
            }, 1000));

            connection.on('employeesChanged', debounce(() => {
                fetchEmployees(dispatch);
            }, 1000));

            connection.on('eventBusState', debounce((state) => {
                dispatch({ type: 'setEventBusState', value: state});
            }), 10000);
        })
        .catch(err => {
            toast.error('Fehler bei der Kommunikation mit dem Server', {
            });
        });
}
