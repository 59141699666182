import styled from 'styled-components';
import { Link } from "react-router-dom";

export const Wrapper = styled.div`
    width: 100%;
    min-height: 100%;
    background: linear-gradient(180deg,#fafafa 23%,#fafafa);
`;

export const MenuTile = styled(Link)`
    display: inline-block;
    width: 100%;
    text-decoration: none;
    //transition: background-color .25s linear;
    color: #404040;
    font-weight: 300;
    padding: .5rem 1rem;
    // border-radius: 2px;
    display: flex;
    align-content: center;
    // border-bottom: 1px solid rgba(3, 3, 3, .3);
    background: white;
    // border-radius: 3px;
    // margin: 7.5px 0;
    // box-shadow: 0 10px 10px rgba(0,0,0,.05);
    border-bottom: 1px solid #cccccc;
    position: relative;
    min-height: 65px;

    &:first-of-type {
        border-top: 1px solid #cccccc;
    }

    ${props => props.big && `
        min-height: 85px;
    `}    

    ${props => props.active && `
        background: rgba(3, 3, 3, .1); 
    `}    
`;

export const MenuTileContent = styled.div`
    // display: flex;
`;

export const MenuCategory = styled.p`
    font-size: 14px;
    color: #333;
    font-weight: 500;
    margin-right: 20px;
    margin-left: 15px;
    padding-top: 30px;
    margin-top: 0;
    text-transform: uppercase;
`;

export const MenuTileTitle = styled.span`
    color: #333;
    font-weight: 500;
`;

export const MenuTileTrainer = styled.div`
    color: #999;
    font-size: 14px;
    display: block;
`;

export const MenuTileMeta = styled.span`
    font-size: 14px;
    display: block;
`;

export const MenuTileHour = styled.span`
    display: block;
    position: absolute;
    right: 15px;
    font-size: 14px;
`;

export const EventColor = styled.div`
    height: 15px;
    width: 15px;
    border-radius: 50%;
    display: inline-block;
    margin-right: 10px;
    margin-top: 5px;
    
    ${props => props.color && `
        background: ${props.color}; 
    `}
`;
