import React, { useContext } from "react";
import DataTable from "react-data-table-component";
import Checkbox from '@material-ui/core/Checkbox';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { useParams} from "react-router-dom";

import {store} from "../store/store";
import {putParticipant} from "../utils/api";
import styled from "styled-components";
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";
import FormLabel from "@material-ui/core/FormLabel";
import FormControl from "@material-ui/core/FormControl";

const SpinnerWrapper = styled.span`
    div {
        vertical-align: top;
    }
    svg {
        transform: scale(0.5);
    } 
`;

const StyledFormControlLabel = styled(FormControlLabel)`
    span {
        font-size: 13px ;
    }
`;

export default function ParticipentList({ participants, title, currentEvent }) {
    const { id } = useParams();
    let { dispatch } = useContext(store);

    const columns = [
        {
            name: 'Name',
            selector: 'name',
            sortable: false,
            cell: row => <span>{row.firstName} {row.lastName}</span>,
        },
        {
            name: 'E-Mail',
            sortable: false,
            selector: 'email',
        },
        {
            name: 'Telefon',
            selector: 'phone',
        },
        {
            name: 'Anwesenheit',
            selector: 'id',
            sortable: false,
            grow: "2",
            cell: row => row.status === 'waitinglist' ? '' : <div>
                <FormControl component="fieldset">
                    <RadioGroup row aria-label="position" name="position" value={row.status}
                        onChange={(event) => {
                            const data = {
                                ...row,
                                status: event.target.value,
                            };
                            putParticipant(id, data, dispatch);
                            dispatch({ type: 'setUpcomingEventsParticipantionPending', value: {
                                    participant: row,
                                    event: currentEvent,
                                }});
                        }}
                        disabled={!!row.loading}
                    >
                        <StyledFormControlLabel
                            value="participated"
                            control={<Radio color="primary" />}
                            label="Teilgenommen"
                            labelPlacement="top"
                            disabled={!!row.loading}
                        />
                        <StyledFormControlLabel
                            value="noshowup"
                            control={<Radio color="primary" />}
                            label="Nicht teilgenommen"
                            labelPlacement="top"
                            disabled={!!row.loading}
                        />
                        <StyledFormControlLabel
                            value="none"
                            control={<Radio color="primary" />}
                            label="Kein Status"
                            labelPlacement="top"
                            disabled={!!row.loading}
                        />
                    </RadioGroup>
                </FormControl>
                <SpinnerWrapper>{ row.loading ? <CircularProgress /> : '' }</SpinnerWrapper>
            </div>,
        },
    ];

    const titleTrans = {
        participants: 'Teilnehmer',
        waitinglist: 'Warteliste',
    };

    return (
        <div>
            <DataTable
                title={titleTrans[title]}
                columns={columns}
                data={participants}
            />
        </div>
    );
}
