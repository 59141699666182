import {ParticipentsNumber} from "../styles/page-header";
import React from "react";

export function getFormatedParticipantsNumber(participants, maxAttendees) {
    const attendees = participants
        .filter(item => ['none', 'participated', 'noshowup'].includes(item.status));

    const waitinglist = participants
        .filter(item => ['waitinglist'].includes(item.status));

    if(attendees.length >= maxAttendees && waitinglist.length === 0) {
        return `Voll`;
    }

    if(attendees.length >= maxAttendees) {
        return `Voll (+ ${waitinglist.length} Warteliste)`;
    }

    return `${attendees.length} / ${maxAttendees}`;
}

export function getFormatedParticipantsTile(participants, maxAttendees) {
    const attendees = participants
        .filter(item => ['none', 'participated', 'noshowup'].includes(item.status));

    const waitinglist = participants
        .filter(item => ['waitinglist'].includes(item.status));

    if(attendees.length >= maxAttendees) {
        return (<>
            <ParticipentsNumber>Voll</ParticipentsNumber>
            <span> + { waitinglist.length } Warteliste</span>
        </>);
    }

    return (<>
        <ParticipentsNumber>{ participants.length } </ParticipentsNumber>
        <span> / { maxAttendees } Plätze</span>
    </>);
}

export function getAttendeesNumber(participants) {
    return participants
        .filter(item => ['none', 'participated', 'noshowup'].includes(item.status)).length;
}
