import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { v4 as uuidv4 } from 'uuid';

export function request(method, path, body) {
    const auth = JSON.parse(sessionStorage.getItem('mc-auth'));
    const header = {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + auth.token,
    };

    if(['PUT', 'POST'].includes(method.toUpperCase())) {
        header['X-Request-ID'] = uuidv4();
    }

    const config = {
        method: method,
        headers: header,
    };

    if(body) {
        config.body = JSON.stringify(body);
    }
    return fetch(path, config)
}

function displayError() {
    toast.error('Fehler bei der Kommunikation mit dem Server', {
        // position: toast.POSITION.BOTTOM_RIGHT
    });
}

export function fetchCourses(dispatch) {
    request('GET', '/api/courses' )
        .then(response => response.json())
        .then(courses => {
            dispatch({ type: 'setCourses', value: courses});
        })
        .catch(error => {
            dispatch({ type: 'setError', value: {
                    courses: true,
                }});
            displayError();
        });
}

export function fetchEmployees(dispatch) {
    request('GET', '/api/employees' )
        .then(response => response.json())
        .then(courses => {
            dispatch({ type: 'setEmployees', value: courses});
        })
        .catch(error => {
            dispatch({ type: 'setError', value: {
                    employees: true,
                }});
            displayError();
        });
}

export function fetchLocations(dispatch) {
    request('GET', '/api/locations' )
        .then(response => response.json())
        .then(courses => {
            dispatch({ type: 'setLocations', value: courses});
        })
        .catch(error => {
            dispatch({ type: 'setError', value: {
                    locations: true,
                }});
            displayError();
        });
}

export function fetchUpcomingEvents(dispatch) {
    request('GET', '/api/events/upcoming' )
        .then(response => response.json())
        .then(courses => {
            dispatch({ type: 'setUpcomingEvents', value: courses});
        })
        .catch(error => {
            dispatch({ type: 'setError', value: {
                    upcomingEvents: true,
                }});
            displayError();
        });
}

export function fetchEvents(dispatch) {
    request('GET', '/api/events' )
        .then(response => response.json())
        .then(courses => {
            dispatch({ type: 'setEvents', value: courses});
        })
        .catch(error => {
            dispatch({ type: 'setError', value: {
                    events: true,
                }});
            displayError();
        });
}

export function fetchCourse(courseId, dispatch) {
    return request('GET', '/api/courses/' + courseId)
        .then(response => response.json())
        .then(courses => {
            dispatch({ type: 'currentCourse', value: courses});
        })
        .catch(error => {
            dispatch({ type: 'setError', value: {
                currentCourse: true,
            }});
            displayError();
        });
}

export function putParticipant(eventId, data, dispatch) {
    return request('PUT', `/api/events/${eventId}/participant`, data)
        .then(response => response.json())
        .then(courses => {
            // dispatch({ type: 'currentCourse', value: courses});
        })
        .catch(error => {
            dispatch({ type: 'setError', value: {
                    currentCourse: true,
                }});
            displayError();
        });
}
