import React, {useContext} from "react";
import {
    useParams,
} from "react-router-dom";
import styled from "styled-components";
import {store} from "../store/store";
import {
    EventColor,
    MenuTile,
    Wrapper
} from "../styles/sidebar";

const MenuTileTitle = styled.div`
    color: #333;
    font-weight: 500;
    margin-top: 12px;
`;

const EventColorMargin = styled(EventColor)`
    margin-top: 15px;
`;

export default function CourseListSidebar() {
    let { id } = useParams();
    const { state: { courses } } = useContext(store);

    return (
        <Wrapper>
            {
                courses.map((item) =>
                    <MenuTile key={item.id} to={`/courses/${item.id}`} active={item.id === id ? 1 : 0}>
                        <EventColorMargin color={item.color}></EventColorMargin>
                        <MenuTileTitle>{item.name}</MenuTileTitle>
                    </MenuTile>
                )
            }
        </Wrapper>
    );
}
