import React, {useContext, useEffect, useRef} from "react";
import { useParams } from "react-router-dom";
import { trackPromise, usePromiseTracker } from 'react-promise-tracker';
import Loader from 'react-loader-spinner';

import 'm-react-splitters/lib/splitters.css';
import styled from 'styled-components';

import CourseSeries from "../components/CourseSeries";
import {H1} from "../styles/globals";
import {store} from "../store/store";
import {
    fetchCourse,
} from "../utils/api";
import {
    Header,
    InnerContent,
} from "../styles/page-header";
import * as moment from "moment";
import { DateTime, ChartComponent, Legend, Inject, SeriesCollectionDirective, SeriesDirective, StackingAreaSeries } from '@syncfusion/ej2-react-charts';


const SpinnerWrapper = styled.div`
    width: 100%;
    height: 100;
    display: flex;
    justify-content: center;
    alignItems: center;
`;


const primaryxAxis = {
    valueType: 'DateTime',
    intervalType: 'Days',
};
const primaryyAxis = {
    interval: 1,
};

const palette = ["#D00000", "#333333", "#6FAAB0", "#C4C24A"];
const legendSettings = { visible: true, position: 'Top' };

const ChartWrapper = styled.div`
    position: relative;
    width: 100%;
    height: 500px;
`;

function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
        ref.current = value;
    });
    return ref.current;
}

export default function Course() {
    let { id } = useParams();
    const { promiseInProgress } = usePromiseTracker();
    const { dispatch, state: { courses, currentCourse, errors } } = useContext(store);
    const prevId = usePrevious(id)

    useEffect(() => {
        trackPromise(
            fetchCourse(id, dispatch)
        )

    }, [dispatch, id]);

    const isLoading = id !== prevId || promiseInProgress;

    const currentCourseByCourses = courses.find(course => course.id === id);

    if(!currentCourseByCourses) {
        return '';
    }

    if(errors.currentCourse) {
        return (<div>
            <h2>Fehler</h2>
            <p>Die angeforderte Resource konnte leider nicht geladen werden!</p>
        </div>);
    }

    const time = moment().subtract('1', 'year').utc();

    let stats = currentCourse && currentCourse.courseSeries
            .reduce((carry, series) => carry.concat(series.stats),[])
            .sort((a, b) => new Date(a.date) - new Date(b.date))
            .filter(item => {
                const date = moment(item.date);
                return date.isAfter(time);
            })
            .map(item => {
                item.date = moment(item.date).format("YYYY-MM-DDT00:00:00.000") + "Z";
                return item;
            })
            .reduce((carry, item) => {
                let found = false;

                const newCarry = carry.map(search => {
                    if(search.date === item.date && search.type === item.type) {
                        search.value = search.value + item.value;
                        found = true;
                    }
                    return { ...search };
                });

                if(found) {
                    return newCarry;
                }
                carry.push(item)

                return carry;
            }, [])
            .reduce((rv, x) => {
                (rv[x.type] = rv[x.type] || []).push(x);
                return rv;
            }, {});

    return (
        <>
            <Header>
                <H1>{ currentCourseByCourses.name }</H1>
                <p>{ currentCourseByCourses.description }</p>
            </Header>
            <InnerContent>
                { isLoading && <SpinnerWrapper><Loader type="ThreeDots" color="red" height="100" width="100" /></SpinnerWrapper>}
                {
                    !isLoading &&
                        <div>
                            <ChartWrapper>
                                <ChartComponent id={'charts'+id} primaryyAxis={primaryyAxis} primaryXAxis={primaryxAxis} palettes={palette} legendSettings={legendSettings}>
                                    <Inject services={[StackingAreaSeries, DateTime, Legend]} />
                                    <SeriesCollectionDirective>
                                        {stats.participated && <SeriesDirective dataSource={stats.participated} name='Anwesend' xName='date' yName='value' type='StackingArea'/>}
                                        {stats.noshowup && <SeriesDirective dataSource={stats.noshowup} name='Nicht anwesend' xName='date' yName='value' type='StackingArea'/>}
                                        {stats.waitinglist && <SeriesDirective dataSource={stats.waitinglist} name='Warteliste' xName='date' yName='value' type='StackingArea'/>}
                                    </SeriesCollectionDirective>
                                </ChartComponent>
                            </ChartWrapper>
                        </div>
                }
                {
                    !isLoading && currentCourse && currentCourse.courseSeries.map((item, key) => <CourseSeries key={item.id} id={item.id} courseSeries={item}/>)
                }
            </InnerContent>
        </>
    );
}
