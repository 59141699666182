import styled from "styled-components";

export const Header = styled.div`
    background-color: #333333;
    border-bottom: #D00000 solid 5px;
    color: #fff;
    padding: 30px;
    padding-bottom: 80px;
`;

export const Card = styled.div`
    position: relative;
    z-index: 2;
    background-color: #fff;
    border-radius: 3px;
    box-shadow: 0 10px 40px rgba(0,0,0,.2);
    margin: 15px;
    padding: 10px 15px;
    width: calc(33% - 30px);
    transform: translate3d(0, 0, 0);
`;

export const CardTitle = styled.div`
    font-size: 20px;
    margin-bottom: 15px;
`;

export const CardContent = styled.div`
    display: flex;
    align-items: center;
`;

export const CardList = styled.div`
    display: flex;
    padding: 0 15px;
    transform: translateY(-50px);
`;

export const TrainerName = styled.div`
    margin-left: 15px;
`;

export const ParticipentsNumber = styled.span`
    color: #D00000;
    font-size: 30px;
`;

export const BigText = styled.span`
    // color: #D00000;
    font-size: 30px;
`;

export const InnerContent = styled.div`
    margin: 30px;
`;

export const TrainerAvatar = styled.img`
    border-radius: 50%;
    height: 50px;
    width: 50px;
    
`;
