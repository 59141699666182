import styled from 'styled-components';

export const H1 = styled.h1`
    font-weight: 400;
    font-size: 2rem;
    margin-bottom: .5rem;
    margin-top: 0;
`;

export const H2 = styled.h1`
    font-size: 22px;
    color: #999;
    font-weight: 400;
    margin-bottom: 0;
`;

export const SideBar = styled.div`
    // background: red;
    // background: linear-gradient(180deg,#fafafa 23%,#fafafa);
    // max-width: 240px;
    width: 100%;
    // padding: 15px;
    height: 100%;
    overflow-y: auto;
`;

export const Content = styled.div`
    flex: 1;
    // margin: 0 15px;
    width: calc(100%-240px);
    overflow-y: auto;
    height: 100%;
    // height: auto !important;
`;

export const PageWrapper = styled.div`
    display: flex;
    flex-direction: inline;
    height: 100%;
    width: 100%;
`;
