import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { StateProvider } from './store/store';

const search = window.location.search;
const params = new URLSearchParams(search);
const token = params.get('t');

ReactDOM.render(
  <React.StrictMode>
      <StateProvider>
        <App token={token}/>
      </StateProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
